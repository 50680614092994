import React, { useState, useRef } from "react"
import PropTypes from 'prop-types'
import { graphql } from "gatsby"
// ---------------------------------------------
import Gallery from "../components/gallery"
import Hyperlink from "../components/hyperlink"
import Layout from "../components/layout"
import Markdown from "../components/markdown"
import Materials from "../components/productMaterials"
import Revealer from "../components/revealer"
import ScrollToLink from "../components/scrollto"
import Sections from "../components/sections"
import Swatches from "../components/swatches"
import SEO from "../components/seo"
import useSettings from "../components/useSettings"
// ---------------------------------------------

/*
  These help group the swatches by material so we can map the components
  that way.
*/
function groupBy(list, key = "material") {
  return list.reduce((objectsByKey, obj, i) => {
    const value = obj[key]
    objectsByKey[value] = (objectsByKey[value] || []).concat(obj)
    return objectsByKey;
  }, {})
}

function flatten(productSwatches) {
  return productSwatches.map(node => {
    if (!node.swatch) {
      console.log(node)
      return false
    }
    return {
      material: node.swatch.material.type,
      ...node
    }
  })
}

const ProductPage = ({ data }) => {

  const {
    title,
    gallery,
    thumbnail,
    landscapeGallery,
    priceWithNote,
    basePrice,
    description,
    seoDescription,
    hideEnquiryButton,
    details,
    dimensions,
    seeFabricleatherOptions,
    fabricleatherOptions,
    timberOptions,
    customiseYour,
    frame,
    fabric,
    cushionOptions,
    sections,
    tearSheet
  } = data.contentfulProduct

  const { customiseCopy } = useSettings();

  // ------------------
  // Looping through the sets of swatches to group them by materials

  const fabrics = fabricleatherOptions && Object.values(
    groupBy(
      flatten(fabricleatherOptions)
    )
  )
  const finishes = timberOptions && Object.values(
    groupBy(
      flatten(timberOptions)
    )
  )

  // ------------------
  // Letting the <Revealer /> components know when the page is scrolling

  const scrollTarget = useRef();
  const [isScrolling, setScrolling] = useState(false)

  const onScrollStart = () => {
    setScrolling(true)
  }

  // ------------------
  // Markup...

  return (
    <Layout>
      <SEO title={title} description={seoDescription} shareImage={thumbnail} />

      {/*
        -------------------------------------
        LANDSCAPE GALLERY (IF NEEDED)
        -------------------------------------
      */}
      {landscapeGallery && (
        <Revealer force={isScrolling}>
          <section className="gutters outer">
            <div className="gallery-wrapper-landscape pad">
              {gallery && (
                <Gallery className="fs-gallery" pictureClassName="fs cover" images={landscapeGallery} />
              )}
            </div>
          </section>
        </Revealer>
      )}

      {/*
        -------------------------------------
        PRODUCT SHOTS GALLERY & PRODUCT INFO
        -------------------------------------
      */}
      <Revealer force={landscapeGallery ? true : false}>
        <section className="gutters outer">
          <div className="product-hero">
            <div className="product-hero__gallery span-6 pad">
              <div className="gallery-wrapper">
                {gallery && (
                  <Gallery className="fs-gallery" pictureClassName="fs cover" images={gallery} />
                )}
              </div>
            </div>
            <div className="span-6 pad">
              <div className="max32">
                <div className="p3 mbx2">
                  <h3>{title}</h3>
                  <p>
                    <em>{priceWithNote}</em>
                  </p>
                </div>
                <Markdown className="md p3 has-links" field={description} />
                <Markdown className="md p3" field={details} />

                <div className="outerx2">
                  {(fabrics || finishes) && (
                    <p className="p2 outerx2">
                      <ScrollToLink className="link" target={scrollTarget} startCallback={onScrollStart}>{seeFabricleatherOptions || 'See Fabric/Leather options'}</ScrollToLink>
                    </p>
                  )}

                  {!hideEnquiryButton && <Enquire title={title} />}
                </div>

                {dimensions && (
                  <>
                    <h5>Dimensions</h5>
                    <Markdown className="md" field={dimensions} />
                  </>
                )}

                {(frame || fabric || cushionOptions) && (
                  <Materials {...data.contentfulProduct} />
                )}

                {tearSheet && tearSheet.file && (
                  <p className="outerx2 has-links">
                    <Hyperlink href={tearSheet.file.url}>
                      Download spec sheet
                    </Hyperlink>
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      </Revealer>

      {/*
        -------------------------------------
        SECTIONS
        -------------------------------------
      */}
      <Sections sections={sections} bypassRevealer={isScrolling} />

      {/*
        -------------------------------------
        SWATCHES
        -------------------------------------
      */}
      {(fabrics || finishes) && (
        <Revealer force={isScrolling}>
          <div className="fabric-options" ref={scrollTarget}>
            <section className="guttersx2 innerx2">
              <div className="span-6 indent">
                <h3 className="mbx2">{customiseYour ? customiseYour : 'Customise'}</h3>
                <Markdown className="md p4" field={customiseCopy} />
              </div>
            </section>
            {fabrics && (
              <section className="bdr-top innerx2">
                <div className="swatches">
                  {fabrics.map((array, i) => (
                    <Swatches swatches={array} basePrice={basePrice} key={`swatch-set-${i}`} />
                  ))}
                </div>
              </section>
            )}
            {finishes && (
              <div className="innerx4">
                <section className="guttersx2 innerx2">
                  <p className="p4 indent">Timbers</p>
                </section>
                <section className="innerx2 bdr-top">
                  <div className="swatches">
                    {finishes.map((array, i) => (
                      <Swatches
                        swatches={array}
                        basePrice={basePrice}
                        hideVariants
                        hideZoom
                        key={`timber-swatch-set-${i}`}
                      />
                    ))}
                  </div>
                </section>
              </div>
            )}
          </div>
        </Revealer>
      )}

      {/*
        -------------------------------------
        PRODUCT FOOTER
        -------------------------------------
      */}
      <section className="bg-champagne innerx2 gutters">
        <div className="flex-grid">
          <div className="span-4 gutters" style={{
            paddingBottom: '15vw'
          }}>
            {dimensions && (
              <>
                <h3 className="mbx2">Dimensions</h3>
                <Markdown className="md p2 outer" field={dimensions} />
              </>
            )}
            <Markdown className="md p2 outer" field={details} />
            {!hideEnquiryButton && <Enquire title={title} />}
            {tearSheet && tearSheet.file && (
              <p className="outer has-links">
                <Hyperlink href={tearSheet.file.url}>
                  Download spec sheet
                </Hyperlink>
              </p>
            )}
          </div>
          <div className="span-4 gutters p2 no-bullets" style={{
            paddingBottom: '15vw'
          }}>
            {(frame || fabric || cushionOptions) && (
              <Materials {...data.contentfulProduct}>
                <h3 className="mbx2">Materials</h3>
              </Materials>
            )}
          </div>
        </div>
      </section>

    </Layout>
  )
}

/*
  -------------------------------------
  HELPER COMPONENTS
  -------------------------------------
*/
const Enquire = ({ title }) => (
  <a href={`mailto:orders@sedilia.com?subject=Enquiry about ${title}`} className="btn outerx2" target="_blank" rel="noopener noreferrer">
    Enquire
  </a>
)

ProductPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ProductPage

/*
  -------------------------------------
  GRAPHQL
  -------------------------------------
*/
export const pageQuery = graphql`
  query ProductPageQuery($slug: String!){
    contentfulProduct(slug: {eq: $slug}) {
      ...Product
    }
  }
`

export const productFragment = graphql`
  fragment Product on ContentfulProduct {
    title
    slug
    # shortDescription = thumbnail description for homepage...
    shortDescription
    seoDescription
    # heroImage = thumbnail on homepage...
    thumbnail: heroImage {
      fluid(maxWidth: 1600) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    gallery {
      id
      fluid(maxWidth: 1600) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    landscapeGallery {
      id
      fluid(maxWidth: 2400) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    summary {
      childMarkdownRemark {
        html
      }
    }
    priceWithNote
    basePrice
    thumbnailPrice
    description {
      childMarkdownRemark {
        html
      }
    }
    hideEnquiryButton
    details {
      childMarkdownRemark {
        html
      }
    }
    dimensions {
      childMarkdownRemark {
        html
      }
    }
    materials {
      childMarkdownRemark {
        html
      }
    }
    frame {
      childMarkdownRemark {
        html
      }
    }
    fabric {
      childMarkdownRemark {
        html
      }
    }
    cushionOptions {
      childMarkdownRemark {
        html
      }
    }
    tearSheet {
      file {
        url
      }
    }
    customiseYour
    seeFabricleatherOptions
    fabricleatherOptions {
      ...ProductSwatch
    }
    timberOptions {
      ...ProductSwatch
    }
    sections {
      ...Section
    }
  }
`
